/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 58.8.11-v202404151628
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/hybrid/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqAuthApiClass {
 
   constructor() {}

  /**
   * @summary Get a collection of authentication providers
   */
  public getAuthProviders(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/auth/providers`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AuthProvidersOutputV1>;
  }

  /**
   * @summary Login
   */
  public login(
    body: models.AuthInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/auth/login`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.UserOutputV1>;
  }

  /**
   * @summary Invalidate the session to be completely unusable
   */
  public logout(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/auth/logout`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<string>;
  }

}


export const sqAuthApi = new sqAuthApiClass();
